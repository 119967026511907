import React, { useEffect, useRef, useState } from 'react';

interface FadeInSectionProps {
  children: React.ReactNode;
}

const FadeInSection: React.FC<FadeInSectionProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // 見えている要素の監視を解除
          }
        });
      },
      {
        threshold: 0.1, // 10%画面に入ったらアニメーションを開始
        rootMargin: '0px 0px -100px 0px', // 100px余裕を持たせる
      }
    );

    if (domRef.current) {
      observer.observe(domRef.current);
    }

    return () => {
      if (domRef.current) {
        observer.unobserve(domRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={domRef}
      className={`transition-opacity duration-2000 ease-out transform ${
        isVisible ? 'opacity-100 translate-y-0 delay-2000 animate-fadeInUp' : 'opacity-0 translate-y-12'
      }`}
    >
      {children}
    </div>
  );
};

export default FadeInSection;
