import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto flex flex-col justify-center items-center text-center">
        <Link to="/#" className="mb-4">
          <img src="/images/fricton_logo2.png" alt="FRiCTON Logo" className="h-20" />
        </Link>
        <p className="mt-2 py-5">
          <a href="/security-policy" className="text-gray-300 px-3 hover:underline">情報セキュリティ方針</a> 
          <a href="/protection-policy" className="text-gray-300 px-3 hover:underline ml-2">個人情報保護方針</a>
          <a href="/privacy-policy" className="text-gray-300 px-3 hover:underline ml-2">プライバシーポリシー</a>
        </p>
        <p>&copy; 2024 株式会社FRiCTON. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
