import React, { useEffect, useState } from 'react';

interface PolkadotsBackgroundProps {
  circleColor?: string;
}

const PolkadotsBackground: React.FC<PolkadotsBackgroundProps> = ({ circleColor }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change breakpoint if necessary
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const circles = isMobile
    ? [
        { size: '20vw', top: '-20px', left: '-20px' },
        { size: '15vw', top: '20%', left: '20%' },
        { size: '8vw', top: '40%', left: '15%' },
        { size: '12vw', top: '60%', left: '60%' },
        { size: '10vw', top: '-50px', left: '70%' },
        { size: '6vw', top: '80%', left: '85%' },
        { size: '25vw', top: '70%', left: '-20%' },
        { size: '25vw', top: '90%', left: '30%' },
      ]
    : [
        { size: '15vw', top: '-50px', left: '-50px' }, // Large top-left circle
        { size: '12vw', top: '20%', left: '20%' }, // Medium middle-left circle
        { size: '5vw', top: '40%', left: '15%' }, // Small circle in middle
        { size: '9vw', top: '60%', left: '60%' }, // Medium circle bottom-right
        { size: '10vw', top: '-50px', left: '70%' }, // Medium circle top-right
        { size: '6vw', top: '80%', left: '85%' }, // Small bottom-right circle
        { size: '20vw', top: '70%', left: '-20%' }, // Very large bottom-left circle
        { size: '25vw', top: '90%', left: '30%' },
      ];

  return (
    <div className="absolute inset-0 z-0 overflow-hidden">
      {circles.map((circle, index) => (
        <div
          key={index}
          className="absolute rounded-full"
          style={{
            width: circle.size,
            height: circle.size,
            top: circle.top,
            left: circle.left,
            backgroundColor: circleColor || 'rgba(158, 167, 198, 0.3)', // Default to RGBA color
          }}
        />
      ))}
    </div>
  );
};

export default PolkadotsBackground;
