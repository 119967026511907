const API_ENDPOINT:string = process.env.REACT_APP_API_ENDPOINT ?? "";

class ApiService {
    private baseUrl: string;
    public api_endpoint: string;

    constructor(baseUrl: string, api_endpoint: string = API_ENDPOINT) {
      this.baseUrl = baseUrl;
      this.api_endpoint = api_endpoint;
    }
  
    // 汎用的なGETリクエストメソッド
    async get<T>(endpoint: string, queryParams?: Record<string, any>, headers?: Record<string, string>): Promise<T> {
      try {
        const url = new URL(`${this.baseUrl}${endpoint}`);
        if (queryParams) {
          Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));
        }
  
        const response = await fetch(url.toString(), {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', ...headers },
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
  
        return await response.json();
      } catch (error) {
        console.error(`GET request failed: ${error}`);
        throw error; // 呼び出し元でエラー処理を行うために再スロー
      }
    }
  
    // 汎用的なPOSTリクエストメソッド
    async post<T>(endpoint: string, body: any, headers?: Record<string, string>): Promise<T> {
      try {
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', ...headers },
          body: JSON.stringify(body),
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
  
        return await response.json();
      } catch (error) {
        console.error(`POST request failed: ${error}`);
        throw error;
      }
    }
  
    // PUTリクエストメソッド
    async put<T>(endpoint: string, body: any, headers?: Record<string, string>): Promise<T> {
      try {
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', ...headers },
          body: JSON.stringify(body),
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
  
        return await response.json();
      } catch (error) {
        console.error(`PUT request failed: ${error}`);
        throw error;
      }
    }
  
    // DELETEリクエストメソッド
    async delete<T>(endpoint: string, headers?: Record<string, string>): Promise<T> {
      try {
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', ...headers },
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
  
        return await response.json();
      } catch (error) {
        console.error(`DELETE request failed: ${error}`);
        throw error;
      }
    }
  }
  
  export const apiService = new ApiService(API_ENDPOINT); // 基本のベースURLを指定
  