import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { marked } from 'marked';
import { Users, Lightbulb, Rocket, BarChart, Cog, CheckCircle, Laptop2, TrendingUp, Target, Search, Zap, LineChart, Wrench } from 'lucide-react'
import FadeInSection from './FadeInSection';
import PageBasicTemplate from './PageBasicTemplate';
import { apiService } from '../utils/ApiService';
import DateFormatter from '../utils/DateFormatter';
import ServicesWeCanDo from './ServicesWeCanDo';
import PolkadotsBackground from './PolkadotsBackground';

interface ServiceItem {
  id: number;
  title: string;
  published_date: string;
  updated_date: string;
  category: string;
  content: string;
}

const ServiceArticleTechSupport: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [article, setArticle] = useState<ServiceItem | null>(null);
  const [contentHTML, setContentHTML] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  // useEffect(() => {
  //   const fetchArticle = async () => {
  //     if (id) {
  //       try {
  //         const data = await apiService.get<ServiceItem>(`/Service-items/${id}`); 書き換え
  //         setArticle(data);
          
  //         // マークダウンをHTMLに変換する処理を即座に実行
  //         const html = await marked.parse(data.content);
  //         setContentHTML(html);
  //       } catch (error) {
  //         console.error('Error fetching the Service article:', error);
  //         setError('記事の取得中にエラーが発生しました。');
  //       }
  //     }
  //   };

  //   fetchArticle();
  // }, [id]);

  // if (error) {
  //   return <p>{error}</p>;
  // }

  // if (!article) {
  //   return <p>記事を読み込んでいます...</p>;
  // }

  const sections = [
    {
      title: "弊社の強み",
      color: "bg-blue-100",
      icon: <Cog className="w-12 h-12 text-blue-600" />,
      subsections: [
        {
          title: "積極的な改善提案",
          icon: <Users className="w-8 h-8 text-blue-600" />,
          features: [
            "目先の改善だけでなく、長期的な目線で改善を提案",
            "非効率に感じている業務を根本から改善",
            "データ可視化による客観的な評価、提案"
          ],
          keyPoints: []
        },
        {
          title: "幅広い知識で最適な提案",
          icon: <Lightbulb className="w-8 h-8 text-blue-600" />,
          features: [
            "ネットワーク・サーバー・セキュリティ",
            "Web・モバイルアプリ開発",
            "業務効率化・DX",
            "AI・組み込み系IoTデバイス"
          ],
          keyPoints: []
        },
        {
          title: "丸投げ可能",
          icon: <Rocket className="w-8 h-8 text-blue-600" />,
          features: [
            "わからないことはとりあえず丸投げ！",
            "やりたいことを伝えるだけでOK",
            "必要なタスクを洗い出し提案します"
          ],
          keyPoints: []
        }
      ]
    }
  ]

    return (
      <>     
          {/* Services Cards */}
          <FadeInSection>
              <ServicesWeCanDo />
          </FadeInSection>

          <div className="bg-gray-50 py-8 md:py-16">
            <div className="max-w-7xl mx-auto">
              <FadeInSection> 
                {sections.map((section, sectionIndex) => (
                  <div key={sectionIndex}>
                    <h2 className="text-2xl md:text-3xl font-bold text-gray-700 text-center mb-4 md:mb-8">{section.title}</h2>
                    <div className="grid md:gap-4 md:grid-cols-2 lg:grid-cols-3">
                      {section.subsections.map((subsection, subsectionIndex) => (
                        <div key={subsectionIndex} className="mt-2 md:mt-0 bg-white shadow-lg rounded-lg overflow-hidden flex flex-col">
                          <div className="p-3 flex-grow">
                            {subsection.icon && (
                              <div className="flex items-center justify-center mb-6">
                                <div className={`${section.color} rounded-full p-2 p-3 mr-2`}>
                                  {subsection.icon}
                                </div>
                              </div>
                            )}
                            <h3 className="text-lg md:text-md font-semibold text-gray-900 text-center mb-4">{subsection.title}</h3>
                            <ul className="space-y-2 mb-2">
                              {subsection.features.map((feature) => (
                                <li className="flex items-start">
                                  <div><CheckCircle className="w-5 h-5 text-green-500 mr-2" /></div>
                                  <span className="text-gray-700">{feature}</span>
                                </li>
                              ))}
                            </ul>
                            <ul className="space-y-3">
                              {subsection.keyPoints.map((point, pointIndex) => (
                                <li key={pointIndex} className="flex items-start">
                                  <CheckCircle className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                                  <span className="text-gray-700">{point}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </FadeInSection> 
            </div>
          </div>
      </>
    );
};

export default ServiceArticleTechSupport;