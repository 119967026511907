import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="fixed top-0 left-0 w-full p-6 z-50">
      <div className="flex justify-between items-center">
        {/* ロゴ */}
        <Link to="/" className="flex items-center">
          <img src="/images/fricton_logo.png" alt="FRiCTON Logo" className="h-14" />
        </Link>

        {/* ハンバーガーメニュー（スマホサイズ） */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-gray-500 focus:outline-none">
            {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>

        {/* ナビゲーションメニュー（デスクトップサイズ） */}
        <nav className="hidden md:flex space-x-4 font-bold p-2 ps-5 pe-5 rounded-full bg-gray-100">
          <Link to="/company" className="text-gray-700 p-2 hover:text-gray-400 transition duration-300">
            会社情報
          </Link>
          <Link to="/service" className="text-gray-700 p-2 hover:text-gray-400 transition duration-300">
            サービス内容
          </Link>
          <Link to="/works" className="text-gray-700 p-2 hover:text-gray-400 transition duration-300">
            お仕事実績
          </Link>
          <Link to="/news" className="text-gray-700 p-2 hover:text-gray-400 transition duration-300">
            お知らせ
          </Link>
          <Link to="/contact" className="text-gray-700 p-2 hover:text-gray-400 transition duration-300">
            お問い合わせ
          </Link>
          <Link to="/recruit" className="bg-blue-gradient text-white px-4 py-2 rounded-full shadow-neumorphic transition duration-300">
            採用はこちら
          </Link>
        </nav>
      </div>

      {/* ハンバーガーメニューのオーバーレイ（スマホサイズ） */}
      <div
        className={`fixed inset-0 z-40 bg-[#f7f9fa] bg-opacity-95 p-6 flex flex-col items-center justify-center space-y-6 transform transition-transform duration-300 ${
          menuOpen ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        {/* 閉じるボタン */}
        <button
          onClick={toggleMenu}
          className="absolute top-6 right-6 text-gray-500 focus:outline-none"
        >
          <FaTimes size={24} />
        </button>

        {/* メニューアイテム */}
        <Link to="/" onClick={toggleMenu} className="text-xl text-gray-500 hover:bg-blue-gradient hover:bg-clip-text hover:text-transparent transition duration-300 font-bold">
          トップページ
        </Link>
        <Link to="/company" onClick={toggleMenu} className="text-xl text-gray-500 hover:bg-blue-gradient hover:bg-clip-text hover:text-transparent transition duration-300 font-bold">
          会社情報
        </Link>
        <Link to="/service" onClick={toggleMenu} className="text-xl text-gray-500 hover:bg-blue-gradient hover:bg-clip-text hover:text-transparent transition duration-300 font-bold">
          サービス内容
        </Link>
        <Link to="/works" onClick={toggleMenu} className="text-xl text-gray-500 hover:bg-blue-gradient hover:bg-clip-text hover:text-transparent transition duration-300 font-bold">
          お仕事実績
        </Link>
        <Link to="/news" onClick={toggleMenu} className="text-xl text-gray-500 hover:bg-blue-gradient hover:bg-clip-text hover:text-transparent transition duration-300 font-bold">
          お知らせ
        </Link>
        <Link to="/contact" onClick={toggleMenu} className="text-xl text-gray-500 hover:bg-blue-gradient hover:bg-clip-text hover:text-transparent transition duration-300 font-bold">
          お問い合わせ
        </Link>
	<Link to="/recruit" onClick={toggleMenu} className="text-xl bg-blue-gradient text-white p-4 font-bold rounded-md">
          採用はこちら
        </Link>
      </div>
    </header>
  );
};

export default Header;

