import React, { useEffect, useState } from 'react';
import FadeInSection from '../components/FadeInSection';
import PageBasicTemplate from '../components/PageBasicTemplate';
import CompanyInfoSection from '../components/CompanyInfoSection';
import { apiService } from '../utils/ApiService';

// JSONデータの型定義
interface Image {
  url: string;
}
interface GreetingItem {
  id: number;
  name: string;
  position: string;
  image: Image;
  description: string;
  personal_history: string;
}

const CompanyOverview: React.FC = () => {
  const [Greeting, setGreeting] = useState<GreetingItem>(); // JSONデータを格納する状態


  useEffect(() => {
    apiService.get<GreetingItem>('/greeting-item')
      .then((data: GreetingItem) => setGreeting(data))
      .catch((error: any) => console.error('Error fetching news articles:', error));
  }, []);

  return (
    <PageBasicTemplate title='会社情報' sub_title='私たちは、IT技術を駆使して新しい価値を創造し、未来を形作る企業です。'>

      <div className="py-4 md:py-8 container mx-auto">
        <div className="md:bg-white md:p-12">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-700 mb-8 md:mb-16 text-center">経歴・代表挨拶</h2>
          
          <div className="flex flex-col md:flex-row items-center justify-center md:justify-start">
            <div className="flex flex-col items-center md:items-center md:w-1/3 mb-8 md:mb-0">
              <img
                src={`${apiService.api_endpoint}${Greeting?.image.url}`}
                alt="CEO"
                className="rounded-full w-48 h-48 object-cover mb-4 shadow-lg"
              />
              <h3 className="text-xl md:text-sm lg:text-xl font-semibold text-center md:text-left">{Greeting?.name}</h3>
              <p className="text-lg md:text-sm lg:text-lg text-gray-600 text-center md:text-left">{Greeting?.position}</p>
            </div>

            <div className="md:w-2/3 text-gray-700 leading-relaxed">
              <h3 className="text-2xl font-bold text-gray-700 text-center md:text-left">経歴</h3>
              <FadeInSection>
                <div
                    className="whitespace-pre-wrap md:my-8 my-4 md:mx-10 mx-4" 
                    dangerouslySetInnerHTML={{ __html: Greeting?.personal_history! }}
                />
              </FadeInSection>
            </div>
          </div>

          <div className="text-gray-700 leading-relaxed md:my-8 my-4 md:mx-10 mx-4">
            <FadeInSection>
              <h3 className="text-2xl font-bold text-gray-700 mb-8 text-center">代表挨拶</h3>
              <div 
                  className="mt-8 whitespace-pre-wrap" 
                  dangerouslySetInnerHTML={{ __html: Greeting?.description! }}
              />
            </FadeInSection>
          </div>
        </div>
      </div>

      <CompanyInfoSection />

    </PageBasicTemplate>

  );
};

export default CompanyOverview;
