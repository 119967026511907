import React from 'react';
import CompanySection from '../components/AbboutUsSection';
import ServicesSection from '../components/ServicesSection';
import WorksSection from '../components/WorksSection';
import CompanyInfoSection from '../components/CompanyInfoSection';
import NewsSection from '../components/NewsSection';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import '../index.css';

import HeroSection from '../components/HeroSection';

const Home: React.FC = () => {
  return (
    <>
      <HeroSection />
      <NewsSection />
      <CompanySection />
      <ServicesSection />
      <WorksSection />
      <CompanyInfoSection />
      <ContactSection title='お問い合わせ'/>

      <Footer />
    </>
  );
};

export default Home;

