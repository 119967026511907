import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import gsap from 'gsap';
import PolkadotsBackground from './PolkadotsBackground';

const AbboutUsSection: React.FC = () => {
  const { ref: sectionRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  // Animation using GSAP
  useEffect(() => {
    if (inView) {
      gsap.to('.company-vision', { opacity: 1, y: 0, duration: 1.5, ease: 'power3.out' });
      gsap.to('.company-about', { opacity: 1, y: 0, duration: 1.5, ease: 'power3.out', delay: 0.5 });
    }
  }, [inView]);

  return (
    <div ref={sectionRef} className="relative w-full py-4 md:py-8 bg-gray-50 overflow-hidden">
      {/* Add the PolkadotsBackground component */}
      <PolkadotsBackground circleColor="rgba(158, 167, 198, 0.3)" />

      <div className="relative z-10 flex flex-col justify-center items-center space-y-4 md:space-y-8">
        <div className="company-vision transform translate-y-10 opacity-0 transition text-center w-full max-w-3xl">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-600 mb-2">会社の想い</h2>
          <p className="text-lg md:text-xl text-gray-600 md:mb-2 bg-opacity-70 px-4 md:px-6 md:py-4 rounded-lg">
            「FRiCTON」は、摩擦と革新を組み合わせた新しい価値を提供する企業です。私たちは、ITの力を駆使し、クライアントが直面するあらゆる挑戦を共に乗り越え、未来に向けた最適なソリューションを提案します。
          </p>
        </div>

        <div className="company-about transform translate-y-10 opacity-0 transition text-center w-full max-w-3xl">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-600 mb-2">私たちについて</h2>
          <p className="text-lg md:text-xl text-gray-600 bg-opacity-70 px-4 md:px-6 md:py-4 rounded-lg">
            FRiCTONは、WebシステムやiOSモバイルアプリの開発、保守、運用。ネットワーク運用保守と幅広いIT知識と経験を持っています。私たちのチームは、技術革新と問題解決を通じて、クライアントのビジネスを次のレベルへと導くことを目指しています。
          </p>
        </div>
      </div>
    </div>
  );
};

export default AbboutUsSection;
