import React, { useEffect, useRef } from 'react';
import PageBasicTemplate from './PageBasicTemplate';

declare global {
  interface Window {
    TimerexCalendar: () => void;
  }
}

const TimeRexCalendar: React.FC = () => {
  const initialized = useRef(false);

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    const script = document.createElement('script');
    script.src = 'https://asset.timerex.net/js/embed.js';
    script.id = 'timerex_embed';
    script.async = true;

    script.onload = () => {
      setTimeout(() => {
        if (window.TimerexCalendar) {
          window.TimerexCalendar();
        }
      }, 100);
    };

    document.body.appendChild(script);

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <div
      id="timerex_calendar"
      data-url="https://timerex.net/s/g.komaki_005f/475eb7b3"
      className="mb-12"
    ></div>
  );
};

const InterviewArrangement: React.FC = () => {
  return (
    <PageBasicTemplate title="" sub_title="">
      <div className="bg-gray-50 min-h-screen">
        <section className="py-16 px-4">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold mb-12 text-center">面談の日程を選択</h2>
            <TimeRexCalendar />
          </div>
        </section>
      </div>
    </PageBasicTemplate>
  );
};

export default InterviewArrangement;