import React, { ReactElement } from 'react';
import { CheckCircle, Cloud, Box, Users } from 'lucide-react';
import { 
  Clock, 
  Settings, 
  FileText, 
  BarChart2, 
  Shield 
} from 'lucide-react';
import FadeInSection from './FadeInSection';


interface FeatureItem {
  number: string;
  title: string;
  description: string;
}

interface SupportItem {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface ServiceItem {
  icon: ReactElement;
  title: string;
  description: string;
}


const ServiceArticleNetwork: React.FC = () => {
  const services = [
    {
      title: "オンプレ/クラウド対応可能",
      icon: <Cloud className="w-8 h-8 text-blue-600" />,
      features: [
        "オンプレミスとクラウドの最適な提案",
        "要望に応じたカスタマイズ",
        "拡張性を考慮したシステム設計"
      ]
    },
    {
      title: "セキュリティと冗長性",
      icon: <Shield className="w-8 h-8 text-blue-600" />,
      features: [
        "ネットワークの安定稼働と迅速なリカバリー",
        "データ暗号化による堅牢なサーバー",
        "CCNP資格保有者による安心の保守運用"
      ]
    },
    {
      title: "最新コンテナ技術の活用",
      icon: <Box className="w-8 h-8 text-blue-600" />,
      features: [
        "Kubernetes、Dockerの活用",
        "マイクロサービスアーキテクチャ対応",
        "迅速なデプロイと柔軟な拡張性"
      ]
    }
  ];

  const features: FeatureItem[] = [
    {
      number: "01",
      title: "最適化された設計",
      description: "小規模から数万人規模にわたるネットワーク設計経験を活かし、お客様の環境に最適なソリューションを提供します。"
    },
    {
      number: "02",
      title: "サーバー監視自動化",
      description: "各社の属人的な監視体制を最大限自動化し、運用コストの軽減と効率の向上を図ります。"
    }
  ];

  const supportReasons = [
    {
      image: "/images/service_icon/network_manual.jpg",
      title: "手動管理の限界",
      description: "手動でのネットワーク監視は、膨大な時間と労力を要し、効率的な運用が困難。"
    },
    {
      image: "/images/service_icon/network_costly.jpg",
      title: "ITコストの増大",
      description: "データトラフィックの増加により、管理や運用にかかるコストが年々増加。"
    },
    {
      image: "/images/service_icon/network_limitations.jpg",
      title: "デジタル化の進展",
      description: "進化するデジタル環境に対応するため、ネットワーク構成の柔軟な見直しが必要。"
    }
  ];

  const serviceItems: ServiceItem[] = [
    {
      icon: <Clock className="w-6 h-6 text-blue-500" />,
      title: "24時間365日の運用体制",
      description: "お客様のシステムを休むことなく監視・対応する体制を整え、安心のサポートを提供します。"
    },
    {
      icon: <Settings className="w-6 h-6 text-blue-500" />,
      title: "効率的な運用保守の自動化対応",
      description: "業務の効率化を目指し、定型作業や監視を自動化することでスピーディーで確実な保守を実現します。"
    },
    // {
    //   icon: <Repeat className="w-6 h-6 text-blue-500" />,
    //   title: "定常業務対応",
    //   description: "日々のルーチン業務をしっかりとこなし、システムの安定稼働をサポートします。"
    // },
    {
      icon: <FileText className="w-6 h-6 text-blue-500" />,
      title: "運用保守ドキュメントの作成整理",
      description: "運用に関するドキュメントをわかりやすく整備し、業務の引継ぎや効率的な運用体制を支えます。"
    },
    {
      icon: <BarChart2 className="w-6 h-6 text-blue-500" />,
      title: "月次インシデントレポート作成",
      description: "毎月発生したインシデントの分析を行い、課題を可視化し改善へと繋げます。"
    },
    {
      icon: <Shield className="w-6 h-6 text-blue-500" />,
      title: "定期的なシステムアップデート",
      description: "最新のセキュリティアップデートを適用し、安全性とパフォーマンスを維持します。"
    },
    {
      icon: <Users className="w-6 h-6 text-blue-500" />,
      title: "企業様に合わせた柔軟な対応",
      description: "記載のサポート以外にも企業様に合わせて柔軟に対応が可能なためご相談ください。"
    },
  ];


  return (
    <FadeInSection>
    <div className="text-gray-700">

      {/* ヘッダーセクション */}
      <section className="max-w-6xl mx-auto mb-8 md:mb-16">
        <h1 className="text-xl md:text-3xl font-bold mb-4 md:mb-6 text-left">
          安定した運用で<br />
          サービスの基盤をサポート
        </h1>
        <p className="text-gray-600 leading-relaxed">
          FRICTONは安定したネットワークとインフラ環境を実現し、効率的な運用・保守を通じて企業のサービス基盤を支えます。
        </p>
      </section>


      <section className="max-w-6xl mx-auto mb-8 md:mb-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-2 md:mb-4">
          <div className="relative h-64 hidden md:block">
            <img 
              src="/images/service_icon/network_superiority.jpg" 
              alt="Superiority" 
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
          <div>
            <h2 className=" mb-2 md:mb-4 text-xl md:text-2xl font-bold">FRiCTONの優位性 </h2>
            <h3 className="text-md md:text-lg mb-4 md:mb-4">優れた専門性を持つエンジニアに設計から運用までお任せください。</h3>
            <div className="relative h-64 mb-4 md:mb-16 block md:hidden">
              <img 
                src="/images/service_icon/network_superiority.jpg" 
                alt="Superiority" 
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div>
            {features.map((feature) => (
              <div key={feature.number} className="">
                <h4 className="text-lg text-blue-500 font-bold mb-2">{feature.title}</h4>
                <p className="text-md text-gray-600 mb-3 leading-6">{feature.description}</p>
              </div>
            ))}
            </div>
          </div>
        </div>
          <div className="grid md:gap-4 md:grid-cols-2 lg:grid-cols-3">
          {services.map((service, index) => (
            <div key={index} className="mt-2 md:mt-0 bg-white shadow-lg rounded-lg overflow-hidden flex flex-col">
              <div className="p-3 flex-grow">
                <div className="flex items-center mb-6">
                  <div className="bg-blue-100 rounded-full p-3 mr-2">
                    {service.icon}
                  </div>
                  <h4 className="text-md font-semibold text-gray-900">{service.title}</h4>
                </div>
                <ul className="space-y-2 mb-2">
                  {service.features.map((feature, fIndex) => (
                    <li key={fIndex} className="flex items-start">
                      <div><CheckCircle className="w-5 h-5 text-green-500 mr-2" /></div>
                      <span className="text-gray-700">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
          </div>
        </section>

        <section className="max-w-6xl mx-auto mb-8 md:mb-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <h2 className="text-xl md:text-2xl font-bold">次世代のネットワーク運用</h2>
              <h3 className="text-md md:text-lg">企業のデジタル基盤としての重要性を増すネットワークインフラは変革期を迎えています。</h3>
              <img
                src="/images/service_icon/network_future.jpg" 
                alt="Future"
                className="w-full h-64 object-cover rounded-lg block md:hidden"
              />
              <p className="text-md text-gray-600 leading-relaxed">
              ネットワークやサーバー運用の安定を支えるため、各社のシステムで行われている手動作業を自動化し、安全に運用コストの軽減と効率の向上を図ります。
              </p>
            </div>
            <div className="relative h-64 md:block hidden">
              <img 
                src="/images/service_icon/network_future.jpg" 
                alt="Future" 
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          </div>
          
          <div className="text-center pt-6 md:pt-16">
            <h3 className="text-lg md:text-xl font-bold md:mb-12 mb-8 text-center relative inline-block">
              <span className="relative inline-block">
                <span className="absolute inset-x-0 -bottom-0 h-2 bg-blue-200 w-full"></span>
                <span className="relative z-10">日々の運用で、</span>
              </span>
              <br className='md:hidden' />
              <span className="relative inline-block">
                <span className="absolute inset-x-0 -bottom-0 h-2 bg-blue-200 w-full"></span>
                <span className="relative z-10">こんなお悩みありませんか？</span>
              </span>
            </h3>
          </div>

          <div className="grid grid-cols-3 gap-2 md:gap-4">
            {supportReasons.map((reason, index) => (
              <div key={index} className="text-center">
                {reason.image ? (
                  <div className="relative w-24 h-24 mx-auto mb-4">
                    <img
                      src={reason.image}
                      alt={reason.title}
                      className="w-full h-full object-cover rounded-full border-4"
                    />
                  </div>
                ) : (
                  <div className="w-24 h-24 mx-auto mb-4 bg-gray-200 rounded-full"></div>
                )}
                <h5 className="text-sm md:text-lg font-bold mb-2">{reason.title}</h5>
                <p className="text-sm text-gray-600 hidden md:block">{reason.description}</p>
              </div>
            ))}
          </div>
      </section>

      {/* Service List Section */}
      <section className="max-w-6xl mx-auto mb-8 md:mb-16">
        <div>
          <h2 className="text-xl md:text-2xl font-bold mb-6 md:mb-12 text-center">FRiCTONが提供するサービス</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-8">
            {serviceItems.map((item, index) => (
              <div key={index} className="flex items-start space-x-2 md:space-x-4 bg-blue-50 p-3 md:p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
                <div className="flex-shrink-0 bg-blue-100 p-2 rounded-full">
                  {item.icon}
                </div>
                <div>
                  <h5 className="font-bold text-lg mb-2">{item.title}</h5>
                  <p className="text-gray-600 leading-relaxed">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

    </div>
    </FadeInSection>
  );
};

export default ServiceArticleNetwork;