import React, { useEffect, useState } from 'react';
import PageBasicTemplate from '../components/PageBasicTemplate';
import FadeInSection from '../components/FadeInSection';
import { apiService } from '../utils/ApiService';

interface PageItem {
  title: string;
  page_name: string;
  html_content: string;
}

const SharedPage: React.FC = () => {
  const [pageItems, setPageItems] = useState<PageItem>();
  useEffect(() => {
    // 現在のパスからリクエストパスを抽出
    const path = window.location.pathname.split('/').pop() || '';
    
    apiService.get<PageItem>(`/${path}`)
      .then((data: PageItem) => setPageItems(data))
      .catch((error: any) => console.error('Error fetching news articles:', error));
  }, []);

  return (
    <PageBasicTemplate title={pageItems?.title || ''} sub_title="">
      <FadeInSection>
      <div
          className="whitespace-pre-wrap md:my-8 my-4 md:mx-10 mx-4" 
          dangerouslySetInnerHTML={{ __html: pageItems?.html_content || '' }}
      />
      </FadeInSection>
    </PageBasicTemplate>
  );
};

export default SharedPage;
