class DateFormatter {
  // UTCのISO8601形式の日時文字列を指定されたフォーマットでJSTに変換して返す
  static formatToJST(isoDateString: string, format: string = 'YYYY年MM月DD日HH時mm分'): string {
    const date = new Date(isoDateString);

    // 日本のタイムゾーン (JST) に変換
    const jstOffset = 9 * 60; // JSTはUTC+9
    const jstDate = new Date(date.getTime() + jstOffset * 60 * 1000);

    const year = jstDate.getUTCFullYear();
    const month = (jstDate.getUTCMonth() + 1).toString().padStart(2, '0'); // 月は2桁にする
    const day = jstDate.getUTCDate().toString().padStart(2, '0');
    const hours = jstDate.getUTCHours().toString().padStart(2, '0');
    const minutes = jstDate.getUTCMinutes().toString().padStart(2, '0');
    const seconds = jstDate.getUTCSeconds().toString().padStart(2, '0');

    // フォーマットに応じた日時文字列を生成
    const formattedDate = format
      .replace('YYYY', year.toString())
      .replace('MM', month)
      .replace('DD', day)
      .replace('HH', hours)
      .replace('mm', minutes)
      .replace('ss', seconds);

    return formattedDate;
  }
}

export default DateFormatter;
