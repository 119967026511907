import React from 'react';
import CompanyInfoSection from './CompanyInfoSection';
import FadeInSection from './FadeInSection';
import Footer from './Footer';


//import React, { useEffect, useRef, useState } from 'react';
interface PageBasicTemplateProps {
  children: React.ReactNode;
  title: string;
  sub_title: string;
}

const PageBasicTemplate: React.FC<PageBasicTemplateProps> = ({ children, title, sub_title }) => {
  return (
    <div className="min-h-screen bg-gray-50">
      
      {/* 共通ページヘッダー */}
      <header className="bg-fricton-gradient text-white pt-28 text-center md:pb-12">
        <h1 className="text-2xl text-gray-700 font-bold  md:text-4xl md:mb-4">{title}</h1>
        <p className="invisible md:visible text-lg text-gray-100">{sub_title}</p>
      </header>

      <section className="my-8 md:my-16 container mx-auto px-4 md:px-8 lg:px-16">
        {children}
      </section>

      {/* 共通ページフッター */}
      <Footer />
    </div>
  );
};

export default PageBasicTemplate;
