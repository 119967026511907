import React from 'react';
import { AppWindow, Bot, Building, Mail, PlaneTakeoff } from 'lucide-react';

interface ServiceCard {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface Step {
  number: string;
  title: string;
  description: string;
}

const ServiceStepsSection: React.FC = () => {
  const leftSteps: Step[] = [
    {
      number: '01',
      title: '要件定義/ヒアリング',
      description: 'クライアントの目的とターゲットに合わせ、サービスの表現方法や機能要件を検討。ユーザー体験とブランドイメージを考慮し、コンセプトと方向性を決定します。'
    },
    {
      number: '02',
      title: 'デザイン設計とプロトタイプ作成',
      description: '要件に基づいてページ構成とデザインを具体化。ワイヤーフレームで直感的な操作を設計し、ブランドイメージを反映させたデザインを構築します。'
    },
    {
      number: '03',
      title: '実装/開発',
      description: 'デザインをもとにサイト・アプリを構築。UIやアニメーション、レスポンシブデザインなどを忠実に再現し、様々なデバイスで快適に動作するよう調整します。'
    }
  ];

  const rightSteps: Step[] = [
    {
      number: '04',
      title: 'テストとデバッグ',
      description: '各機能が正常に動作するか、デバイスやブラウザで表示や操作を確認。エラー修正を行い、信頼性のあるサイトを提供するための品質チェックを行います。'
    },
    {
      number: '05',
      title: '運用/保守',
      description: '公開後も安定した運用と信頼性を確保。コンテンツ更新、セキュリティチェック、アクセス分析を行い、ユーザー体験を向上させ、ビジネス成長を支援します。'
    }
  ];
  return (
    <section className="text-gray-700 max-w-6xl mx-auto">
      <h2 className="text-2xl md:text-3xl font-bold mb-2 md:mb-4 md:text-center">
        アイデアを実現する開発フロー
      </h2>

      <p className="text-lg md:text-lg text-gray-700 md:text-base md:text-center mb-8 md:mb-12">
        FRiCTONの開発フローは、お客様のアイデアをスムーズに実現可能。<br />
        初期のコンセプト設計からリリースまでスピーディーに遂行します。
      </p>

      <div className="pl-4 md:pl-0 grid grid-cols-0 gap-0 md:grid-cols-2 md:gap-10">
        <div className="relative">
          <div className="absolute top-0 bottom-0 w-px bg-gray-300 block" />
            <div className="space-y-4 md:space-y-8">
            {leftSteps.map((step) => (
              <div key={step.number} className="relative flex">
                <div className="relative">
                  <div className="absolute -left-6 flex flex-col items-center justify-center w-12 h-12 bg-gray-700 rounded-full md:w-16 md:h-16 md:-left-8">
                    <span className="text-white text-xs md:text-sm mt-[3px] md:mt-[5px]">step</span>
                    <span className="text-white text-md md:text-xl mt-[-3px] md:mt-[-5px]">{step.number}</span>
                  </div>
                </div>
                <div className="ml-9 md:ml-14">
                  <h3 className="text-blue-600 font-medium text-lg md:text-xl mb-2">
                    {step.title}
                  </h3>
                  <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                    {step.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="relative pt-2 md:pt-0">
          <div className="absolute top-0 bottom-0 w-px bg-gray-300 block" />
            <div className="space-y-4 md:space-y-8">
              {rightSteps.map((step) => (
                <div key={step.number} className="relative flex">
                    <div className="absolute -left-6 flex flex-col items-center justify-center w-12 h-12 bg-gray-700 rounded-full md:w-16 md:h-16 md:-left-8">
                      <span className="text-white text-xs md:text-sm mt-[3px] md:mt-[5px]">step</span>
                      <span className="text-white text-lg md:text-xl mt-[-3px] md:mt-[-5px]">{step.number}</span>
                    </div>
                    <div className="ml-9 md:ml-14">
                      <h3 className="text-blue-600 font-medium text-lg md:text-xl mb-2">
                        {step.title}
                      </h3>
                      <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                        {step.description}
                      </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};


const ServiceArticleWeb: React.FC = () => {
  const serviceCards: ServiceCard[] = [
    {
      icon: <AppWindow className="w-8 h-8" />,
      title: "HP/LP制作",
      description: "企業のブランドイメージやマーケティング目線に合わせたホームページ（HP）やランディングページ（LP）の制作を行なっています。SEOやサイトスピードの最適化、UI/UXの改善などでサイトの価値を高め、ユーザーの離脱を防ぎます。"
    },
    {
      icon: <Bot className="w-8 h-8" />,
      title: "生成AI\nアプリケーション",
      description: "ユーザーの要望に応じて特別な機能を実装するAIアプリケーションの開発を行っています。チャットボット、画像生成、データ分析など、さまざまな用途に対応。独自のAIソリューションでビジネスの可能性を広げます。"
    },
    {
      icon: <Mail className="w-8 h-8" />,
      title: "SNS\nアプリケーション",
      description: "ユーザー間のコミュニケーションを促進するSNSアプリケーションの開発を行っています。リアルタイムチャット、投稿機能、通知機能など、ソーシャルメディアに必要な要素を網羅し、魅力的なプラットフォームを構築します。"
    },
    {
      icon: <PlaneTakeoff className="w-8 h-8" />,
      title: "旅行予約サイト",
      description: "ユーザーが簡単に旅行を計画・予約できる旅行予約サイトの開発。宿泊施設、フライト、レンタカー、アクティビティの検索や予約ができる一元化されたシステムなど、ユーザーのニーズに合わせた機能やインターフェース使いやすさを重視し快適なユーザー体験を実現します。"
    },
    {
      icon: <Building className="w-8 h-8" />,
      title: "業務システム",
      description: "複雑化する業務の自動化や効率化を実現するため、カスタマイズ可能な仕組みを作り上げます。要件定義、設計開発、プロジェクト管理など、専門的な知識と経験を活かしたサービスを提供します。"
    }
  ];

  return (
    <div className="text-gray-700">
      {/* ヘッダーセクション */}
      <section className="max-w-6xl mx-auto mb-8 md:mb-16">
        <h1 className="text-xl md:text-3xl font-bold mb-4 md:mb-6 text-left">
          最新技術を活用し、<br />
          ビジネスの成長を加速させるWebサイト運営をサポート
        </h1>
        <p className="text-gray-600 leading-relaxed">
          最新技術を駆使して、企業の成長を加速させるためのWebサイト、Web
          アプリケーション、Webシステムの開発・運用・保守を提供します。
        </p>
      </section>

      {/* サポート説明セクション */}
      <section className="max-w-6xl mx-auto mb-8 md:mb-16">
        <h2 className="text-xl md:text-3xl font-bold flex flex-wrap items-baseline gap-x-2 mb-4 md:mb-16">
          設計から開発運用保守まで弊社が、
          <div className="mt-1 md:mt-0 relative inline-flex flex-col items-center">
            <span className="text-blue-500 text-xs absolute -top-2 tracking-[0.32em] md:text-sm absolute md:-top-4 md:tracking-[0.57em]">・ ・ ・ ・</span>
            <span className="text-2xl md:text-4xl text-blue-500">まるっと</span>
          </div>
          <span className="whitespace-nowrap">サポートします。</span>
        </h2>

        {/* 開発セクション */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-8 md:mb-16">
          <div>
            <div className="text-center md:text-left">
              <h3 className="text-2xl font-bold mb-2 md:mb-4">開発</h3>
              <h4 className="text-lg md:text-xl font-bold md:mb-8 mb-4 relative inline-block">
                <span className="relative inline-block">
                  <span className="absolute inset-x-0 -bottom-0 h-2 bg-blue-200 w-full"></span>
                  <span className="relative z-10">ビジネスの未来を形にする</span>
                </span>
                <br className='sm:hidden' />
                <span className="relative inline-block">
                  <span className="absolute inset-x-0 -bottom-0 h-2 bg-blue-200 w-full"></span>
                  <span className="relative z-10">プロフェッショナルなWeb開発</span>
                </span>
              </h4>
            {/* モバイル版用画像 */}
            <img 
              src="/images/service_icon/web_dev.jpg"
              alt="Web開発" 
              className="w-full h-64 object-cover rounded-lg block md:hidden"
              />
            </div>
            <p className="text-gray-600 leading-relaxed pt-4 md:pt-0">
            企業のブランド価値を最大化し、優れたユーザー体験を実現する高機能なWebサイトを提供します。<br />
            戦略的なデザインと最先端の技術を組み合わせ、革新的かつスケーラブルなWebソリューションでビジネスの成長を支えます。
            </p>
          </div>
          <div className="relative h-64 hidden md:block">
            {/* PC版用画像 */}
            <img 
              src="/images/service_icon/web_dev.jpg"
              alt="Web開発" 
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>

        {/* 保守セクション */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-8 md:mb-16">
          {/* PC版用画像 */}
          <div className="relative h-64 hidden md:block">
            <img 
              src="/images/service_icon/web_maint.jpg" 
              alt="保守" 
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
          <div>
            <div className='text-center md:text-left'>
              <h3 className="text-2xl font-bold mb-2 md:mb-4">保守</h3>
              <h4 className="text-lg md:text-xl font-bold md:mb-8 mb-4 relative inline-block">
                <span className="relative inline-block">
                  <span className="absolute inset-x-0 -bottom-0 h-2 bg-blue-200 w-full"></span>
                  <span className="relative z-10">安定性と安全性で</span>
                </span>
                <br className='sm:hidden' />
                <span className="relative inline-block">
                  <span className="absolute inset-x-0 -bottom-0 h-2 bg-blue-200 w-full"></span>
                  <span className="relative z-10">システムの基盤を支える</span>
                </span>
              </h4>
            </div>
            {/* モバイル版用画像 */}
            <img 
              src="/images/service_icon/web_maint.jpg" 
              alt="保守" 
              className="w-full h-64 object-cover rounded-lg block md:hidden"
              />
            <p className="text-gray-600 leading-relaxed pt-4 md:pt-0">
            Webサイトやアプリが常に最適な状態を維持できるよう、定期メンテナンスや最新のセキュリティ対策を実施します。<br />
            長期的な安定稼働を実現するため、セキュリティ強化やパフォーマンス改善を定期的に行い、外部からのリスクや技術的劣化に対応します。
            </p>
          </div>
        </div>

        {/* 運用セクション */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-8 md:mb-16">
          <div>
            <div className="text-center md:text-left">
              <h3 className="text-2xl font-bold mb-2 md:mb-4">運用</h3>
              <h4 className="text-lg md:text-xl font-bold md:mb-8 mb-4 relative inline-block">
                <span className="relative inline-block">
                  <span className="absolute inset-x-0 -bottom-0 h-2 bg-blue-200 w-full"></span>
                  <span className="relative z-10">信頼性の高い</span>
                </span>
                <br className='sm:hidden' />
                <span className="relative inline-block">
                  <span className="absolute inset-x-0 -bottom-0 h-2 bg-blue-200 w-full"></span>
                  <span className="relative z-10">日々のサポートと迅速な対応</span>
                </span>
              </h4>
              {/* モバイル版用画像 */}
              <img 
                src="/images/service_icon/web_ope.jpg"
                alt="運用" 
                className="w-full h-64 object-cover rounded-lg block md:hidden"
              />
            </div>
            <p className="text-gray-600 leading-relaxed pt-4 md:pt-0">
            システムの安定稼働を維持するため、日々のモニタリングと技術サポートを通じて、潜在的な問題を未然に防ぎます。<br />
            トラブル発生時には迅速に対応し、継続的な運用改善とシステム最適化を進めることで、信頼性の高いサービス提供を支援します。
            </p>
          </div>
          <div className="relative h-64 hidden md:block">
            {/* PC版用画像 */}
            <img 
              src="/images/service_icon/web_ope.jpg"
              alt="運用" 
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>
      </section>

      {/* サービス紹介セクション */}
      <section className="max-w-6xl mx-auto mb-8 md:mb-16">
        <h2 className="text-xl md:text-3xl font-bold flex flex-wrap items-baseline gap-x-2 my-2 md:mb-16">
          弊社なら
          <div className="mt-1 md:mt-0 relative inline-flex flex-col items-center">
            <span className="text-blue-500 text-xs absolute -top-2 tracking-[0.32em] md:text-sm absolute md:-top-4 md:tracking-[0.7em]">・ ・ ・ ・</span>
            <span className="text-2xl md:text-4xl text-blue-500">幅広い</span>
          </div>
          <span className="whitespace-nowrap">サービス開発が可能です</span>
        </h2>

        <p className="text-xl font-bold mb-4 md:mb-8">例えば...</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-6">
          {serviceCards.map((card, index) => (
            <div key={index} className="p-4 md:p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
              {/* モバイル版でアイコンとタイトルを横並びにする */}
              <div className="flex items-center space-x-2 md:block mb-1 md:mb-4">
                <div className='mb-1 md:mb-4'>{card.icon}</div>
                <h3 className="text-md md:text-xl font-bold whitespace-pre-line">{card.title}</h3>
              </div>
              <p className="text-gray-600 text-sm leading-relaxed">{card.description}</p>
            </div>
          ))}
        </div>
      </section>

      <ServiceStepsSection />
    </div>
  );
};

export default ServiceArticleWeb;
