import { useInView } from 'react-intersection-observer';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; 
import FadeInSection from './FadeInSection';
import { apiService } from '../utils/ApiService';


// JSONデータの型定義
interface Image {
  url: string;
}
interface ServiceItem {
  id: number;
  title: string;
  image: Image;
  caption: string;
  description: string;
  service_name: string;
}


const ServicesSection: React.FC = () => {
  const { ref: sectionRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [services, setServices] = useState<ServiceItem[]>([]); // JSONデータを格納する状態


  useEffect(() => {
    apiService.get<ServiceItem[]>('/service-items')
      .then((data: ServiceItem[]) => setServices(data))
      .catch((error: any) => console.error('Error fetching news articles:', error));
  }, []);


  return (
    <div ref={sectionRef} className="relative w-full overflow-hidden md:py-8 bg-gray-50">
      {/* タイトル - サービス内容 */}
      <h2 className="text-2xl md:text-3xl font-bold text-gray-700 my-4 md:my-8 text-center">
        サービス内容
      </h2>

      {/* Services Cards */}
      <FadeInSection>
        <div className="w-full flex justify-center items-center">
          <div className="md:mx-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 md:gap-8 justify-items-center">
            {/* 共通カードスタイル */}
            {services.map((service, index) => (
              <Link
              to={`/service/${service.service_name}`} // クリック時に詳細ページに遷移
              key={index}
              className="bg-white rounded-lg shadow-md my-2 md:my-0 cursor-pointer transform transition-transform duration-300 hover:-translate-y-1 hover:shadow-xl"
              >
              <div key={index} className="w-[280px] h-[380px] p-6 bg-white rounded-lg shadow-md transform transition-transform duration-300 hover:scale-105">
                <img src={`${apiService.api_endpoint}${service.image.url}`} alt={service.title} className="w-full h-30 object-cover rounded-t-lg mb-4" />
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{service.title}</h3>
                <p className="text-gray-700">{service.caption}</p>
              </div>
              </Link>
            ))}
          </div>
        </div>
      </FadeInSection>
    </div>
  );
};

export default ServicesSection;
