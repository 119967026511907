import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { marked } from 'marked';

import PageBasicTemplate from './PageBasicTemplate';
import { apiService } from '../utils/ApiService';
import DateFormatter from '../utils/DateFormatter';

interface NewsItem {
  id: number;
  title: string;
  published_date: string;
  updated_date: string;
  category: string;
  content: string;
}

const NewsArticle: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [article, setArticle] = useState<NewsItem | null>(null);
  const [contentHTML, setContentHTML] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      if (id) {
        try {
          const data = await apiService.get<NewsItem>(`/news-items/${id}`);
          setArticle(data);
          
          // マークダウンをHTMLに変換する処理を即座に実行
          const html = await marked.parse(data.content);
          setContentHTML(html);
        } catch (error) {
          console.error('Error fetching the news article:', error);
          setError('記事の取得中にエラーが発生しました。');
        }
      }
    };

    fetchArticle();
  }, [id]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!article) {
    return <p>記事を読み込んでいます...</p>;
  }

  return (
    <PageBasicTemplate title={article.title} sub_title={article.category}>
      <div className="my-16 md:my-32 ">
        
        <div className='prose lg:prose-xl mx-auto mb-5 md:mb-10'
          dangerouslySetInnerHTML={{ __html: contentHTML }} 
        />

        <div className='flex justify-end'>
          <p className="text-sm text-gray-500 me-2 md:me-10">
            投稿日: {DateFormatter.formatToJST(article.published_date, 'YYYY年MM月DD日')}
          </p>
          {article.updated_date && (
            <p className="text-sm text-gray-500">
              更新日: {DateFormatter.formatToJST(article.updated_date, 'YYYY年MM月DD日')}
            </p>
          )}
        </div>

      </div>
    </PageBasicTemplate>
  );
};

export default NewsArticle;