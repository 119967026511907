import React from 'react';
import FadeInSection from '../components/FadeInSection';
import PageBasicTemplate from '../components/PageBasicTemplate';
import ContactSection from '../components/ContactSection';


const CompanyOverview: React.FC = () => {
  return (
    <PageBasicTemplate title='お問い合わせ' sub_title='こちらのフォームからお問い合わせください'>
      <FadeInSection>

        <div className="text-gray-700 text-center">
          <p>
          ご相談、ご意見、ご不明な点など必要情報をご入力の上、送信下さい。
          </p>
          <p>
          後ほど担当より回答させていただきます。
          </p>
        </div>
        <ContactSection title='' />
      </FadeInSection>
    </PageBasicTemplate>

  );
};

export default CompanyOverview;
