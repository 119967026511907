import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import CompanyOverview from './pages/CompanyOverview';
import HomePage from './pages/Home';
import Service from './pages/Service'
import News from './pages//News';
import Works from './pages//Works';
import SharedPage from './pages/SharedPage';
import Contact from './pages/Contact';
import RecruitArticle from './pages/Recruit';
import InterviewArrange from './components/InterviewArrange';
import NewsArticle from './components/NewsArticle';
import ServiceArticle from './components/ServiceArticle';

const App: React.FC = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/company" element={<CompanyOverview />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service/:name" element={<ServiceArticle />} />
        <Route path="/news/:id" element={<NewsArticle />} />
        <Route path="/news" element={<News />} />
        <Route path="/works" element={<Works />} />
        <Route path="/security-policy" element={<SharedPage />} />
        <Route path="/privacy-policy" element={<SharedPage />} />
        <Route path="/protection-policy" element={<SharedPage />} />
        
        <Route path="/contact" element={<Contact />} />
        <Route path="/recruit" element={<RecruitArticle />} />
        <Route path="/interview-arange" element={<InterviewArrange />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
