import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import PageBasicTemplate from '../components/PageBasicTemplate';
import FadeInSection from '../components/FadeInSection';
import { apiService } from '../utils/ApiService';

// WorkItemの型定義 (WorkSectionと同じ)
interface Image {
  url: string;
}

interface WorkItem {
  id: number;
  title: string;
  image: Image;
  contentHTML: string;
  category: string;
}

const Works: React.FC = () => {
  const [works, setWorks] = useState<WorkItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const [worksPerPage] = useState(6); // 1ページあたりの表示件数
  const [selectedWork, setSelectedWork] = useState<WorkItem | null>(null);

  const { ref: sectionRef, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  useEffect(() => {
    apiService.get<WorkItem[]>('/work-items')
      .then((data: WorkItem[]) => {
        setWorks(data);
      })
      .catch((error: any) => console.error('Error fetching works:', error));
  }, []);

  // ページネーション
  const indexOfLastWork = currentPage * worksPerPage;
  const indexOfFirstWork = indexOfLastWork - worksPerPage;
  const currentWorks = works.slice(indexOfFirstWork, indexOfLastWork);
  const totalPages = Math.ceil(works.length / worksPerPage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleCardClick = (work: WorkItem) => {
    setSelectedWork(work);
  };

  const closeModal = () => {
    setSelectedWork(null);
  };

  return (
    <PageBasicTemplate title="お仕事実績一覧" sub_title="">
      <div ref={sectionRef}>
        <FadeInSection>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-4 py-10">
            {currentWorks.map((work) => (
              <div
                key={work.id}
                className="relative bg-white rounded-xl shadow-lg overflow-hidden cursor-pointer transition-transform duration-300 transform hover:scale-105"
                onClick={() => handleCardClick(work)}
              >
                <img
                  src={`${apiService.api_endpoint}${work.image.url}`}
                  alt={work.title}
                  className="w-full h-auto object-contain"
                />
                <span className="absolute bottom-2 right-2 bg-[#5B72AA] text-white text-sm px-3 py-1 rounded-full shadow-lg">
                  {work.category}
                </span>
                {work.title && (
                  <h2 className="absolute top-2 left-2 bg-white bg-opacity-70 text-gray-800 text-sm px-2 py-1 rounded">
                    {work.title}
                  </h2>
                )}
              </div>
            ))}
          </div>
        </FadeInSection>
      </div>

      {/* ページネーション */}
      <div className="flex justify-center mt-8 space-x-2">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-4 py-2 rounded-md bg-blue-500 text-white ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
        >
          前のページ
        </button>

        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`px-4 py-2 rounded-md ${currentPage === index + 1 ? 'bg-blue-700 text-white' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
          >
            {index + 1}
          </button>
        ))}

        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 rounded-md bg-blue-500 text-white ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
        >
          次のページ
        </button>
      </div>

      {/* モーダル表示(WorkSectionと同じUI) */}
      {selectedWork && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-white rounded-lg p-8 max-w-6xl w-full max-h-[80%] overflow-auto">
            <button
              className="absolute top-4 right-4 text-black text-2xl font-bold"
              onClick={closeModal}
            >
              &times;
            </button>
            <img
              src={`${apiService.api_endpoint}${selectedWork.image.url}`}
              alt={selectedWork.title}
              className="w-full h-auto object-contain mb-4"
            />
            <span className="absolute bottom-4 right-8 bg-[#5B72AA] shadow-lg text-white text-sm px-3 py-1 rounded-full">
              {selectedWork.category}
            </span>
            {selectedWork.contentHTML && (
              <div dangerouslySetInnerHTML={{ __html: selectedWork.contentHTML }} />
            )}
          </div>
        </div>
      )}
    </PageBasicTemplate>
  );
};

export default Works;
