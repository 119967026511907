import React, { useEffect } from 'react';
import { MapPin, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ArrowUpRight } from 'lucide-react';

const RecruitmentTable: React.FC = () => {
  const tableData = [
    {
      category: "募集職種",
      content: [
        "・WEBエンジニア",
        "・モバイルエンジニア",
        "・ネットワーク・サーバーエンジニア",
        "・IT事務・その他 ※まずはお話ししましょう"
      ]
    },
    {
      category: "雇用形態",
      content: ["・正社員", "・業務委託"]
    },
    {
      category: "勤務場所",
      content: [
        "・事務所（渋谷道玄坂）",
        "・フルリモート可 ※業務内容とスキルに応じて"
      ]
    },
    {
      category: "勤務時間",
      content: ["基本勤務時間：AM10:00 - 18:30 ※相談しましょう"]
    },
    {
      category: "休日・休暇",
      content: [
        "・完全週休2日制(土日)",
        "・夏季休暇",
        "・年末年始休暇",
        "・有給休暇",
        "・結婚休暇",
        "・産前産後・育児休暇"
      ]
    },
    {
      category: "福利厚生",
      content: [
        "・社会保険完備(健康保険、厚生年金保険、雇用保険、労災保険)",
        "・その他、産休・育休などの支援制度あり"
      ]
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <table className="w-full">
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
              <th className="px-6 py-4 bg-gray-100 text-left text-gray-900 font-bold w-1/4">
                {row.category}
              </th>
              <td className="px-6 py-4 text-gray-700">
                {row.content.map((item, i) => (
                  <div key={i} className="mb-2 last:mb-0">
                    {item}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Recruit: React.FC = () => {
  return (
    <div className="bg-gray-50 min-h-screen">
      <section className="text-white py-40 px-4 bg-cover bg-center relative">
        <div className="absolute inset-0 bg-black/50 z-0">
            <img 
            src="https://fricton.com:4000/uploads/info_office_20241123_2fea787cc0.png" 
            alt="Background" 
            className="w-full h-full object-cover mix-blend-overlay"
            />
        </div>
        <div className="relative z-10 max-w-4xl mx-auto text-center">
            <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">
            一緒に働ける仲間を募集しています！
            </h2>
            <p className="text-2xl mb-8">
                まずはカジュアルにお話ししませんか？
            </p>
            
            <Link to="/interview-arange" className="bg-white text-blue-700 px-8 py-4 rounded-full text-lg font-bold hover:bg-blue-50 transition-colors">
                カジュアル面談に申し込む
                <ArrowRight className="inline-block ml-2 w-5 h-5" />
            </Link>
            </div>
        </div>
      </section>


      <div className="pb-20">

        <section className="py-8 px-4">
            <div className="max-w-4xl mx-auto">
            <p className="text-gray-700 pb-10 text-center font-bold ">
                未経験も含めて積極的に採用中！<br />
                立ち上げ時期に伴い、将来のリードエンジニア、CTOとなるメンバーも募集しています。<br />
                その他CxO候補も募集しています。
            </p>
            </div>
        </section>
      
        <section className="py-6 px-4">
            <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold mb-12 text-center">募集要項</h2>
            <RecruitmentTable />
            </div>
        </section>

        <section className="pb-6 px-4">
            <div className="max-w-4xl mx-auto">
            <div className="bg-white p-8 rounded-xl shadow">
                <div className="flex items-start gap-4">
                <MapPin className="w-6 h-6 text-blue-500 flex-shrink-0 mt-1" />
                <div>
                    <h3 className="font-bold mb-2">オフィス所在地</h3>
                    <p className="text-gray-600">
                    〒150-0043<br />
                    東京都渋谷区道玄坂1丁目17-9 ヴェラハイツ道玄坂 704
                    </p>
                </div>
                </div>
            </div>
          </div>
        </section>

        <section className="relative h-[40vh] min-h-[300px] overflow-hidden">
            <div className="absolute inset-0 max-w-4xl py-6 mx-auto bg-gray-800 rounded-lg text-white text-center">
                <img
                src="https://fricton.com:4000/uploads/interview_arrange_b0d47b8d89.png"
                alt="Hero background"
                className="w-full h-full object-cover opacity-70"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-gray-900/70 to-gray-900/50 rounded-xl" />
            </div>
            
            <div className="relative z-10 h-full flex flex-col items-center justify-center text-white px-4">
                <h1 className="text-3xl font-bold mb-6 flex items-center gap-3">
                一緒に働ける仲間を募集しています
                </h1>
                <p className="text-1xl mb-10">
                まずは気軽にお話ししましょう！
                </p>
                <Link 
                to="/interview-arange" 
                className="bg-white text-gray-900 px-8 py-4 rounded-full text-lg font-bold hover:bg-gray-100 transition-colors"
                >
                カジュアル面談に申し込む
                <ArrowUpRight className="inline-block ml-2 w-5 h-5" />
                </Link>
            </div>
        </section>


        

      </div>

    </div>
  );
};

export default Recruit;