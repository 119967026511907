import React from 'react';
import FadeInSection from './FadeInSection';
import { CheckCircle } from 'lucide-react';

interface Step {
  number: string;
  title: string;
  description: string;
}

const CatchphraseSection: React.FC = () => {
  return (
    <section className="max-w-6xl mx-auto mb-8 md:mb-16">
      <h1 className="text-xl md:text-3xl font-bold mb-4 md:mb-6 text-left">
          未来のビジネスに応えるiOSアプリ開発。<br/>
          技術と信頼のサポート体制で、お客様のビジョンを形にします。
      </h1>
      <p className="mt-4 text-lg text-left">
      最新技術を活用し、ユーザー体験を重視した機能設計と、安定した運用を支えるサポート体制を整備。<br />
      開発から運用・保守まで一貫して対応し、サービスが常に最適なパフォーマンスでビジネスの成長をサポートします。
      </p>
    </section>
  );
};

const DescribeSection: React.FC = () => {
  return (
    <section className="max-w-6xl mx-auto mb-8 md:mb-16">
      <h2 className="text-xl md:text-3xl font-bold flex flex-wrap items-baseline gap-x-2 mb-4 md:mb-16">
        設計から開発運用保守まで弊社が、
        <div className="mt-1 md:mt-0 relative inline-flex flex-col items-center">
          <span className="text-blue-500 text-xs absolute -top-2 tracking-[0.32em] md:text-sm absolute md:-top-4 md:tracking-[0.57em]">・ ・ ・ ・</span>
          <span className="text-2xl md:text-4xl text-blue-500">まるっと</span>
        </div>
        <span className="whitespace-nowrap">サポートします。</span>
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-top mt-10">
        {/* 画像のソースを先に記述 */}
        <div className="relative -mt-[30px] order-1 lg:order-2">
          <img src="/images/service_icon/devtotalsupport_icon.png" alt="total_support" className="mx-auto w-[80%]" />
        </div>

        {/* 説明のソースを後に記述 */}
        <div className="order-2 lg:order-1">
          <ul className="space-y-3 md:space-y-5">
            <li className="flex items-start">
              <div><CheckCircle className="w-5 h-5 text-green-500 mr-2" /></div>
              <span className="text-gray-700">プロジェクトの基盤となるデザインと機能を定義する「設計フェーズ」</span>
            </li>
            <li className="flex items-start">
              <div><CheckCircle className="w-5 h-5 text-green-500 mr-2" /></div>
              <span className="text-gray-700">ユーザーの操作性を考慮し、各機能を具現化する工程「開発フェーズ」</span>
            </li>
            <li className="flex items-start">
              <div><CheckCircle className="w-5 h-5 text-green-500 mr-2" /></div>
              <span className="text-gray-700">サイトやシステムの信頼性を確保し、成長を支援する「運用保守フェーズ」</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};


const ServiceStepsSection: React.FC = () => {
  const leftSteps: Step[] = [
    {
      number: '01',
      title: '要件定義/ヒアリング',
      description: 'クライアントの目的とターゲットに合わせ、サービスの表現方法や機能要件を検討。ユーザー体験とブランドイメージを考慮し、コンセプトと方向性を決定します。'
    },
    {
      number: '02',
      title: 'デザイン設計とプロトタイプ作成',
      description: '要件に基づいてページ構成とデザインを具体化。ワイヤーフレームで直感的な操作を設計し、ブランドイメージを反映させたデザインを構築します。'
    },
    {
      number: '03',
      title: '実装/開発',
      description: 'デザインをもとにサイト・アプリを構築。UIやアニメーション、レスポンシブデザインなどを忠実に再現し、様々なデバイスで快適に動作するよう調整します。'
    }
  ];

  const rightSteps: Step[] = [
    {
      number: '04',
      title: 'テストとデバッグ',
      description: '各機能が正常に動作するか、デバイスやブラウザで表示や操作を確認。エラー修正を行い、信頼性のあるサイトを提供するための品質チェックを行います。'
    },
    {
      number: '05',
      title: '運用/保守',
      description: '公開後も安定した運用と信頼性を確保。コンテンツ更新、セキュリティチェック、アクセス分析を行い、ユーザー体験を向上させ、ビジネス成長を支援します。'
    }
  ];
  return (
    <section className="text-gray-700 max-w-6xl mx-auto">
      <h2 className="text-xl md:text-3xl font-bold mb-2 md:mb-4 md:text-center">
      アイデアを実現する開発フロー
      </h2>

      <p className="text-gray-700 mb-12 text-sm md:text-base md:text-center">
        FRiCTONの開発フローは、お客様のアイデアをスムーズに実現可能。<br />
        初期のコンセプト設計からリリースまでスピーディーに遂行します。
      </p>
        
      <div className="pl-4 md:pl-0 grid grid-cols-0 gap-0 md:grid-cols-2 md:gap-10">
        <div className="relative">
          <div className="absolute top-0 bottom-0 w-px bg-gray-300 block" />
            <div className="space-y-4 md:space-y-8">
            {leftSteps.map((step) => (
              <div key={step.number} className="relative flex">
                <div className="relative">
                  <div className="absolute -left-6 flex flex-col items-center justify-center w-12 h-12 bg-gray-700 rounded-full md:w-16 md:h-16 md:-left-8">
                    <span className="text-white text-xs md:text-sm mt-[3px] md:mt-[5px]">
                      step
                    </span>
                    <span className="text-white text-md md:text-xl mt-[-3px] md:mt-[-5px]">
                      {step.number}
                    </span>
                  </div>
                </div>
                <div className="ml-9 md:ml-14">
                  <h3 className="text-blue-600 font-medium text-lg md:text-xl mb-2">
                    {step.title}
                  </h3>
                  <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                    {step.description}
                  </p>
                </div>
              </div>
            ))}
            </div>
          </div>

          <div className="relative pt-2 md:pt-0">
            <div className="absolute top-0 bottom-0 w-px bg-gray-300 block" />
              <div className="space-y-4 md:space-y-8">
                {rightSteps.map((step) => (
                  <div key={step.number} className="relative flex">
                    <div className="absolute -left-6 flex flex-col items-center justify-center w-12 h-12 bg-gray-700 rounded-full md:w-16 md:h-16 md:-left-8">
                      <span className="text-white text-xs md:text-sm mt-[3px] md:mt-[5px]">step</span>
                    <span className="text-white text-lg md:text-xl mt-[-3px] md:mt-[-5px]">{step.number}</span>
                    </div>
                    <div className="ml-9 md:ml-14">
                      <h3 className="text-blue-600 font-medium text-lg md:text-xl mb-2">
                        {step.title}
                      </h3>
                      <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                        {step.description}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
        </div>
      </div>
    </section>
  );
};

const ServiceArticleMobile: React.FC = () => {
  return (
    <div className="text-gray-700">
      <FadeInSection>
        <CatchphraseSection />
      </FadeInSection>

      <FadeInSection>
        <DescribeSection />
      </FadeInSection>
      
      <FadeInSection>
        <ServiceStepsSection />
      </FadeInSection>
    </div>
  );
};

export default ServiceArticleMobile;
