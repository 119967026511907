import React from 'react';

interface ServiceItem {
  icon: string; // アイコンの画像URL
  title: string;
  caption: string;
}

const services: ServiceItem[] = [
  {
    icon: '/images/service_icon/infoSys_icon.png',
    title: '情シス・ヘルプデスク業務',
    caption: "ITツールのアカウント管理やマニュアル作成、ITに関するお問い合わせトラブル対応等"
  },
  {
    icon: '/images/service_icon/transDitital_icon.png',
    title: '社内DX促進',
    caption: "業務効率化DX支援。"
  },
  {
    icon: '/images/service_icon/itConsul_icon.png',
    title: 'ITコンサルティング',
    caption: "ITツールのアカウント管理やマニュアル作成、ITに関するお問い合わせトラブル対応等"
  },
  {
    icon: '/images/service_icon/siteServerOpe_icon.png',
    title: 'Webホームページ/サーバー保守',
    caption: "ITツールのアカウント管理やマニュアル作成、ITに関するお問い合わせトラブル対応等"
  },
  {
    icon: '/images/service_icon/pcKitting_icon.png',
    title: 'PCキッティング・保守サポート',
    caption: "ITツールのアカウント管理やマニュアル作成、ITに関するお問い合わせトラブル対応等"
  },
];

const ServicesWeCanDo: React.FC = () => {
  return (
    <section className="py-4 bg-gray-50 text-center">
      <h2 className="text-xl md:text-3xl text-gray-700 font-bold mb-6 md:mb-12">弊社ならこんなことができます！</h2>
      <div className="grid max-w-3xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-8"> {/* 上段: 3列 */}
          {services.slice(0, 3).map((service, index) => (
            <div key={index} className="text-center">
              <div className="relative mb-2 md:mb-4">
                <img src={service.icon} alt={service.title} className="mx-auto w-[80%] h-[80%] relative z-10" />
                <div className="absolute inset-0 bg-orange-200 rounded-full -z-10" style={{ padding: '20%' }}></div>
              </div>
              <h3 className="text-lg font-semibold text-gray-700 whitespace-pre-wrap">{service.title}</h3>
              <p className="text-md md:text-xs text-gray-700 whitespace-pre-wrap">{service.caption}</p>
            </div>
          ))}
        </div>
        <div className="grid gap-8 max-w-2xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8 justify-center"> {/* 下段: 2列で中央寄せ */}
            {services.slice(3).map((service, index) => (
              <div key={index} className="text-center">
                <div className="relative mb-4">
                  <img src={service.icon} alt={service.title} className="mx-auto w-[70%] h-[70%] relative z-10" />
                  <div className="absolute inset-0 bg-orange-200 rounded-full -z-10" style={{ padding: '20%' }}></div>
                </div>
                <h3 className="text-lg font-semibold text-gray-700 whitespace-pre-wrap">{service.title}</h3>
                <p className="text-md md:text-xs text-gray-700 whitespace-pre-wrap">{service.caption}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesWeCanDo;
