import React, { useEffect, useState } from 'react';
import PageBasicTemplate from '../components/PageBasicTemplate';
import { motion } from 'framer-motion';
import { apiService } from '../utils/ApiService';
import { Link } from 'react-router-dom'; 

// JSONデータの型定義
interface Image {
  url: string;
}
interface ServiceItem {
  id: number;
  title: string;
  image: Image;
  caption: string;
  description: string;
  service_name: string;
}

// Framer Motion variants for animation
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const fadeInLeft = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.6 } },
};

const fadeInRight = {
  hidden: { opacity: 0, x: 20 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.6 } },
};

const Service: React.FC = () => {
  const [services, setServices] = useState<ServiceItem[]>([]); // JSONデータを格納する状態

  useEffect(() => {
    apiService.get<ServiceItem[]>('/service-items')
      .then((data: ServiceItem[]) => setServices(data))
      .catch((error: any) => console.error('Error fetching news articles:', error));
  }, []);

  return (
    <PageBasicTemplate title="サービス内容" sub_title="私たちの提供するサービス">
      {services.map((service, index) => (
        <Link
          to={`/service/${service.service_name}`} // クリック時に詳細ページに遷移
          key={index}
          className="bg-white rounded-lg shadow-md m-4 cursor-pointer transform transition-transform duration-300 hover:-translate-y-2 hover:shadow-xl"
          >
        <motion.div
          key={service.id}
          className="mb-8 md:mb-12 flex flex-col md:flex-row items-center md:space-x-8"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {/* Service Image */}
          <motion.div
            className="w-full md:w-1/3"
            variants={index % 2 === 0 ? fadeInLeft : fadeInRight} // Alternate animation direction
          >
            <img
              src={`${apiService.api_endpoint}${service.image.url}`}
              alt={service.title}
              className="rounded-lg shadow-lg object-cover w-full h-full"
            />
          </motion.div>

          {/* Service Description */}
          <motion.div
            className="w-full h-0 md:h-auto md:w-1.5/2 text-center md:text-left mt-2 md:mt-0"
            variants={fadeInUp}
            >
            <h3 className="text-lg md:text-2xl font-bold text-gray-700 mb-4">{service.title}</h3>
            <p className="invisible md:visible text-lg text-gray-600 mb-4">{service.caption}</p>
            <div className="invisible md:visible mt-4">
              <p className="text-sm text-gray-500">{service.description}</p>
            </div>
          </motion.div>
        </motion.div>
        </Link>
      ))}
    </PageBasicTemplate>
  );
};

export default Service;